import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

function TrocasDevolucoesPage() {
  return (
    <section className="px-2 py-6 md:px-16">
      <SEO
        keywords={[`about`, `sobre`, `trocas`, `dovolucoes`, `devoluções`]}
        title="Trocas e Devoluções"
      />

      <div className="text-base text-gray-700">
        <div className="px-6 md:px-20 md:mx-20">
          <h4 className="pt-8 text-xl uppercase font-bold">
            Informações gerais, Trocas e Devoluções
          </h4>
          <h4 className="pt-8 uppercase font-bold">Compromisso</h4>
          <div>
            <p className="py-2">
              Nosso compromisso é sua total satisfação nas compras realizadas em
              nosso site. Visando a uma parceria de respeito e confiança,
              criamos a Política de Troca e Devolução, com base no Código de
              Defesa do Consumidor.
            </p>
            <p className="py-2">
              Para evitar qualquer problema com a sua compra, fique atento:
            </p>
            <ul className="list-disc pl-10">
              <li>Com a descrição e ficha técnica do produto;</li>
              <li>Com a cor do produto;</li>
              <li>Com as dimensões do produto.</li>
            </ul>
            <p className="py-2">
              Você deve ficar atento às informações abaixo:
            </p>
            <p className="py-2">
              O prazo para solicitação de troca ou devolução é de 7(sete) dias
              corridos a partir da data de recebimento do produto.
            </p>
            <p className="py-2">
              Todas as mercadorias, oriundas de troca ou cancelamento, deverão
              ser encaminhadas a sóstrass.com.br preferencialmente em sua
              embalagem original, sem indícios de mau uso e sempre com a 1ª via
              da nota fiscal de venda.
            </p>
            <p className="py-2">
              Caso o produto para troca não esteja disponível no momento da
              solicitação, a sóstrass.com.br disponibiliza um vale-cupom no
              valor do produto, o qual poderá ser utilizado para uma nova compra
              no site.
            </p>
            <p className="py-2">
              Para sua segurança, exija que o transportador lhe apresente a Nota
              Fiscal de Coleta, onde constará a descrição do item a ser
              coletado. Exceto correios, que emite o código de objeto de coleta,
              composto por 13 dígitos.
            </p>
            <p className="py-2">
              Constatada qualquer divergência, indícios de mau uso, ausência da
              1ª via da nota fiscal, a sóstrass.com.br fica isenta de aceitar a
              sua solicitação e devolverá o produto ao seu endereço de entrega
              sem a correspondente restituição dos valores pagos.
            </p>
            <p className="py-2">
              Recuse o recebimento do produto e entre em contato com o Serviço
              de Atendimento ao Cliente, caso constate alguma das situações
              abaixo:
            </p>
            <ul className="list-disc pl-10">
              <li>produto avariado no transporte</li>
              <li>embalagem violada;</li>
              <li>produto em desacordo com o pedido.</li>
            </ul>
          </div>
          <h4 className="pt-8 uppercase font-bold">
            Insatisfação com o produto
          </h4>
          <div>
            <p className="py-2">
              As solicitações de desistência do produto serão acatadas em até 7
              (sete) dias corridos após o recebimento do item.
            </p>
            <p className="py-2">
              Toda mercadoria deverá ser encaminhada a sóstrass.com.br em sua
              embalagem original, sem indícios de mau uso e sempre com a 1ª via
              da nota fiscal de venda.
            </p>
            <p className="py-2">
              A retirada do produto será realizada no endereço de entrega
              através dos Correios
            </p>
            <p className="py-2">
              Constatada qualquer divergência, indícios de mau uso, ausência da
              1ª via da nota fiscal, a sóstrass.com.br fica isenta de aceitar a
              solicitação.
            </p>
            <h5>Importante</h5>
            <p className="py-2">
              Havendo indícios de mau uso do produto, a solicitação de devolução
              não será aceita. Neste caso, o produto recolhido pelo Correio será
              devolvido no endereço de entrega, sem a devida restituição de
              valores, e todo o processo de devolução será cancelado.
            </p>
          </div>
          <h4 className="pt-8 uppercase font-bold">Ressarcimento de valores</h4>
          <div>
            <p className="py-2">
              O valor do produto será devolvido de acordo com a forma de
              pagamento utilizada na compra e desde que observadas as condições
              descritas acima.
            </p>
            <h5>Cartão de Crédito</h5>
            <p className="py-2">
              O estorno poderá ocorrer em até 2 (duas) faturas subseqüentes.
              Este procedimento é de responsabilidade da administradora do
              cartão utilizado.
            </p>
            <h5>Pix e Transferência Bancária</h5>
            <p className="py-2">
              O estorno será feito na conta corrente em até 10 (dez) dias úteis.
              Não será concedido crédito a terceiros.
            </p>
          </div>
          <h4 className="pt-8 uppercase font-bold">Avaria</h4>
          <div>
            <p className="py-2">
              Caso o produto seja avariado durante o transporte, recuse o
              recebimento e entre em contato com o Serviço de Atendimento ao
              Cliente.
            </p>
          </div>
          <h4 className="pt-8 uppercase font-bold">Produto com Defeito</h4>
          <div>
            <p className="py-2">
              O prazo para solicitação de troca por defeito ao Serviço de
              Atendimento ao Cliente deve ocorrer em até 7 (sete) dias corridos
              após a data de recebimento.
            </p>
            <p className="py-2">
              A sóstrass.com. br se isenta da responsabilidade de trocar
              qualquer produto em que o defeito tenha ocorrido por mau uso.
            </p>
            <p className="py-2">
              Todas as informações são válidas para compras realizadas pelo site
              www.sóstrass.com.br e TeleVendas e diretamente na Loja da
              sóstrass.
            </p>
          </div>
          <h4 className="pt-8 uppercase font-bold">
            Outros motivos para troca ou devolução
          </h4>
          <div>
            <p className="py-2">
              Se ocorrer qualquer irregularidade no ato da entrega, recuse o
              recebimento da mercadoria e comunique imediatamente nossa Central
              de Atendimento.
            </p>
            <p className="py-2">
              Caso o produto tenha sido recebido, o prazo para solicitação de
              devolução por motivo de irregularidade na entrega será de 48 horas
              a partir do recebimento.
            </p>
            <p className="py-2">
              Apesar de termos todo o cuidado necessário para garantir a entrega
              do pedido dentro das condições desejadas, recomendamos que sejam
              observados alguns pontos no momento do recebimento do pedido:
            </p>
            <ul className="list-disc pl-10">
              <li>embalagem em perfeitas condições;</li>
              <li>todos os produtos adquiridos constam na entrega;</li>
              <li>os produtos estão de acordo com o pedido realizado;</li>
              <li>o pedido está acompanhado da nota fiscal de venda.</li>
            </ul>
          </div>
          <h4 className="pt-8 uppercase font-bold">
            Troca parcial dos produtos
          </h4>
          <div>
            <p className="py-2">
              Em caso de troca parcial de produtos, a sóstrass.com.br não se
              responsabiliza pelos custos de transporte, tanto de envio quanto
              ao recebimento dos produtos, ficando todos os custos por conta do
              cliente.
            </p>
          </div>
          <h4 className="pt-8 uppercase font-bold">Troca na loja sóstrass</h4>
          <div>
            <p className="py-2">
              Todas as suas solicitações devem ser realizadas diretamente ao
              Serviço de Atendimento ao Cliente ou diretamente na loja da
              sóstrass.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TrocasDevolucoesPage;
